( function ( $ ) {
	const swiper = new Swiper('.swiper-container', {
    // Optional parameters
    loop: true,
    autoplay: {
      delay: 7000,
    },
  
    // If we need pagination
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  
    // Navigation arrows
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  gsap.registerPlugin(ScrollTrigger);

  const reveal = gsap.utils.toArray('.reveal');
  reveal.forEach((text, i) => {
    ScrollTrigger.create({
      trigger: text,
      toggleClass: 'active',
      start: "top 85%",
      once: true,
      markers: false,
    })
  });

  const cards = gsap.utils.toArray('.product');

  gsap.set('.item__wrapper', {
    opacity: 0,
    y: 50,
  });

  cards.forEach((item, i) => {
    item.tl = gsap.timeline({ paused: true })
    .to(item.querySelector('.item__wrapper'), {
      opacity: 1,
      y: 0,
    })
  });


  function showCards(batch) {
    batch.forEach(function(target, i) {
      gsap.delayedCall(i * 0.3, function() {
        target.tl.play();
      });
    });
  }

  ScrollTrigger.batch(cards, {
    onEnter: showCards,
    once: true,
    start: "top 85%",
  });

  gsap.utils.toArray(".panel").forEach((panel, i) => {
    ScrollTrigger.create({
      trigger: panel,
    });
    
    ScrollTrigger.create({
      trigger: panel,
      start: "bottom bottom",
    });
  });

} )( jQuery );